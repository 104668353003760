const htmlElement = document.querySelector('html');
const btnOpenMenu = document.querySelector('.js-button-open-overlay');
const btnCloseMenu = document.querySelector('.js-button-close-overlay');
const subMenu = document.querySelector('.js-submenu');

if(btnOpenMenu) {
    btnOpenMenu.addEventListener('click', toggleMenu);
}
if(btnCloseMenu) {
    btnCloseMenu.addEventListener('click', toggleMenu);
}

const SubmenuFunction = require('./brands.js');
//arguments: intervalTime, amount of animations (in animations.scss)
const mySubmenu = new SubmenuFunction(5875, 3);

function toggleMenu(e) {
    e.preventDefault();
    subMenu.classList.toggle('submenu--active');
    htmlElement.classList.toggle('stop-scrolling'); //TODO: check with mobile devices, seems like some might not like that

    subMenu.classList.contains('submenu--active') ? mySubmenu.initFloating() : mySubmenu.clearFloating();
}

//JS Backup for scrolling: https://stackoverflow.com/questions/4770025/how-to-disable-scrolling-temporarily
// function disableScroll() {
//     if (window.addEventListener) // older FF
//         window.addEventListener('DOMMouseScroll', preventDefault, false);
//     s}
//     window.onwheel = preventDefault; // modern standard
//     window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
//     window.ontouchmove = preventDefault; // mobile
//     document.onkeydown = preventDefaultForScrollKeys;
// }

// function enableScroll() {
//     if (window.removeEventListener) {
//         window.removeEventListener('DOMMouseScroll', preventDefault, false);
//     }
//     window.onmousewheel = document.onmousewheel = null;
//     window.onwheel = null;
//     window.ontouchmove = null;
//     document.onkeydown = null;
// }    