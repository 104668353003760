/*** SCSS IMPORT ***/
import '../styles/index.scss';
import { TweenMax, TimelineLite } from "gsap";
// import 'scrollmagic';
// import "scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js"; //issue with global dependency variable, breaks webpack

// __webpack_nonce__ = 'c29tZSBjb29sIHN0cmluZyB3aWxsIHBvcCB1cCAxMjM=';

// var myJSON = require("../my.json");

// var data = require('./data.js');
var generalMethods = require('./general.js');
var brands = require('./brands.js');
var menu = require('./menu.js');
var parallax = require('./parallax.js');

var brandHeadline = document.querySelectorAll('.brand-preview__headline');
generalMethods.addClickEventToArray(brandHeadline, generalMethods.showBrandPage);



// fetch('../test.html')
//     .then(function (response) {
//         console.log('re: ', response.json());
//         return response.json();
//     })
//     .then(function (myJson) {
//         console.log(myJson);
//     });

//standard classic ajax request
// (function () {
//     var httpRequest;
//     document.getElementById("ajaxButton").addEventListener('click', makeRequest);

//     function makeRequest() {
//         console.log('makeRequest');
//         httpRequest = new XMLHttpRequest();
        
//         if (!httpRequest) {
//             alert('Giving up :( Cannot create an XMLHTTP instance');
//             return false;
//         }
//         httpRequest.onreadystatechange = consoleContents;
//         // httpRequest.open('GET', '../index.html');
//         httpRequest.open('GET', markup);
//         // httpRequest.open('GET', myJSON);
//         httpRequest.send();
//     }
    
//     function consoleContents() {
//         console.log('consoleContents');
//         if (httpRequest.readyState === XMLHttpRequest.DONE) {
//             if (httpRequest.status === 200) {
//                 console.log(httpRequest.responseText);
//             } else {
//                 console.log('There was a problem with the request.');
//             }
//         }
//     }
// })();