let data = {
    brands: [
        {
            'name': 'nike #1',
            'src': 'brand-01.jpg'
        },
        {
            'name': 'adidas #2',
            'src': 'brand-02.jpg'
        },
        {
            'name': 'reebok #3',
            'src': 'brand-01.jpg'
        },
        {
            'name': 'puma #4',
            'src': 'brand-02.jpg'
        },
        {
            'name': 'nike #5',
            'src': 'brand-01.jpg'
        },
        {
            'name': 'adidas #6',
            'src': 'brand-02.jpg'
        }
    ]
};

module.exports = data;