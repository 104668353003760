import { TimelineMax } from "gsap";

//scenes
// import { Elastic s} from 'gsap';

//WIP support Emilia
// import 'ScrollMagic/scrollmagic/uncompressed/plugins/animation.gsap';
// import ScrollMagic from 'ScrollMagic';

var controller = new ScrollMagic.Controller();
var brandScenes = []; //array for added scenes
var brandPreviewScenes = []; //similar array addressing different scrollmagic scene 
var activateSidebarScene;

//FUTURE TODO: sync them by the incoming data, to make sure their amount and content matches
const bodyElement = document.querySelector(".js-page-utr20-hub");
const brandElements = document.querySelectorAll(".js-brand-preview");
var brandElementsLength = brandElements.length;
const sidebarElements = document.querySelectorAll(".js-sidebar__element");
const headerContainer = document.querySelector(".js-header-container");
const headerContent = document.querySelector(".js-header-content");
var headerAnimation;
const mainContainer = document.querySelector(".js-main-container");
const sidebar = document.querySelector(".js-sidebar");
var brandTween = [];
const progressBar = document.querySelector('.js-progress-bar');
var progressBarAnimation;
var progressBarTween;

// var complete = function() {
//     console.log('complete!');
// };

/*** SCENES ***/
headerAnimation = new ScrollMagic.Scene({
    triggerElement: headerContainer,
    duration: '150%' //100% only refers to viewport, so only works on specific layouts
})
.setClassToggle(headerContent, 'header-content--active')
.addTo(controller);

progressBarTween = new TimelineMax()
.add([
    TweenMax.from(progressBar, 1, {
        // ease: SlowMo.ease.config(1, 0, false),
        ease: Power1.easeInOut,
        x: "-100%"
    })
]);

progressBarAnimation = new ScrollMagic.Scene({
    triggerElement: brandElements[0],
    duration: bodyElement.offsetHeight-window.outerHeight, //for better UI feel, substract window height
    triggerHook: 1
})
.setTween(progressBarTween)
// .setClassToggle(progressBar, 'progress-bar--active')
.addTo(controller);

for (let i = brandElementsLength-1; i >= 0; i--) {
    var pre01 = i % 2 !== 0 ? "+" : "-";
    var pre02 = i % 2 !== 0 ? "-" : "+";
    
    brandTween[i] = new TimelineMax()
    .add([
        TweenMax.from(brandElements[i], .8, {
            // opacity: .5,
            scale: .75, //interesting, maybe to wild
            y: "-12.5%",
            x: pre01 + "12.5%"
            // onComplete: complete
        }),
        // TweenMax.from(brandElements[i].querySelector(".brand-preview__visuals"), 1, {
        //     // scale: 1.1,
        //     y: "20%"
        // }),
        // TweenMax.from(brandElements[i].querySelectorAll(".brand-preview__image"), 1, {
        //     scale: 1.075
        // }),
        TweenMax.fromTo(brandElements[i].querySelector(".js-brand-preview__image-01"), 1, {
            y: pre01 + "25%"
            // delay: .2 //works transfered on the scroll movement!
        },
        {   
            ease: SlowMo.ease.config(1, 0, false),
            y: pre02 + "25%"
        }),
        TweenMax.fromTo(brandElements[i].querySelector(".js-brand-preview__image-02"), 1, {
            y: pre02 + "25%",
        },
        {
            ease: SlowMo.ease.config(1, 0, false),
            y: pre01 + "25%"
        })
    ]);

    //scene 1: sidebar elements
    brandScenes[i] = new ScrollMagic.Scene({
        triggerElement: brandElements[i],
        duration: brandElements[i].offsetHeight //works when loading is right, i.e. css before js, on dev version does work right, calculations before css is rendered
        // duration: '100%' //100% only refers to viewport, so only works on specific layouts
    })
    .setTween(brandTween[i])
    .setClassToggle(sidebarElements[i], 'sidebar__element--active')
    // .setTween(
    //     TweenMax.from(brandElements[i], 1, {left: 500})
    // )
    .addTo(controller);

    //scene 2: brand-preview element itself
    brandPreviewScenes[i] = new ScrollMagic.Scene({
        triggerElement: brandElements[i],
        triggerHook: 0.9
    })
    .setClassToggle(brandElements[i], 'brand-preview--active')
    .addTo(controller);
}

activateSidebarScene = new ScrollMagic.Scene({
    triggerElement: mainContainer
})
.setClassToggle(sidebar, 'sidebar--active')
.addTo(controller);

//methods
var updateDurationBrandScenes = function(duration) {
    let brandScenesLength = brandScenes.length;
    for (let i = brandScenesLength - 1; i >= 0; i--) {
        brandScenes[i].duration(duration);
    }
};

var checkDurations = function() {
    //to keep performance up, only check first on, since they are all same by height and content
    if (brandElements[0].offsetHeight !== brandScenes[0].duration()) {
        updateDurationBrandScenes(brandElements[0].offsetHeight);
    }
};

window.addEventListener('resize', checkDurations);


//obsolete hotfix due to loading issue in dev mode, should be fixed later with proper node.js express setup or in production
// setTimeout(() => {
//     checkDurations();    
// }, 0);


/*** TWEENS ***/
// TweenMax.to('.header__main-headline', 10, {'transform': 'scale(1.1)'});
// var headerImgs = document.querySelectorAll('.header__image-wrapper');

//example images by random, to do: z-index issues after matrix fixes
// for(let i = 0; i < headerImgs.length; i++) {
//     var pre = i % 2 !== 0 ? "+" : "-";
//     TweenMax.from(headerImgs[i], 2, {
//         x: pre + "100%",
//         scale: .5,
//         ease: Bounce.easeOut
//     });
// }

// var myTween = new TimelineMax()
//     .add([
//         TweenMax.from(brandElements[0], 10, {
//             opacity: .75
//         })
//     ]);
// // TweenMax.to('.header__main-headline', 3, {left: 500});

// // console.log('myTween: ', myTween);

// var parallaxScene = new ScrollMagic.Scene({
//     triggerElement: '.main-container',
//     duration: 500
// })
// .setTween(myTween)
// .addTo(controller);