var generalMethods = {
    mobileEnd: 767,
    shuffleArray: function(givenArray) {
        let j, storedValue; //variables for random Array position and storedValue
        for (let i = givenArray.length - 1; i >= 0; i--) {
            j = Math.floor(Math.random() * (i + 1)); //get new position for current (i) array key-value-pair, by using Math.floor and reference to i, it is assured the spot will be within the array length
            storedValue = givenArray[i]; //save value of the current (i) array key-value-pair
            givenArray[i] = givenArray[j]; //replace the current (i) array key-value-pair with the one from the randomly (j) picked spot
            givenArray[j] = storedValue; //replace the randomly (j) picked array key-value-pair spot with the former current (i) one, which has been saved in let x
            //--> as result, on iteration has switched the current array key-value-pair position with a randomly picked one
        }
    },
    isMobile: function() {
        //since Chrome alters the values according to device settings in dev tools, better us the Math.min value
        let isMobile = Math.min(window.outerWidth, window.innerWidth || 0) <= this.mobileEnd ? true : false;
        return isMobile;
    },
    showBrandPage: function(i) {
        let markup = require("../brand.html");
        let frag = document.createRange().createContextualFragment(markup);
        // console.log('frag: ', frag , ' - index: ', i);
        document.querySelector('body').appendChild(frag);
    },
    addClickEventToArray: function(myArray, methddToCall) {
        if (myArray) {
            for (let i = myArray.length - 1; i >= 0; i--) {
                myArray[i].addEventListener("click", function () {
                    methddToCall(i);
                }, false);
            }
        }
    }
};
/*
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
// function shuffleArray(givenArray) {
//     let j, storedValue; //variables for random Array position and storedValue
//     for (let i = givenArray.length - 1; i >= 0; i--) {
//         j = Math.floor(Math.random() * (i + 1)); //get new position for current (i) array key-value-pair, by using Math.floor and reference to i, it is assured the spot will be within the array length
//         storedValue = givenArray[i]; //save value of the current (i) array key-value-pair
//         givenArray[i] = givenArray[j]; //replace the current (i) array key-value-pair with the one from the randomly (j) picked spot
//         givenArray[j] = storedValue; //replace the randomly (j) picked array key-value-pair spot with the former current (i) one, which has been saved in let x
//         //--> as result, on iteration has switched the current array key-value-pair position with a randomly picked one
//     }
// }
// shuffleArray(brands);

module.exports = generalMethods;