/* TODOs 
- only do the random animation stuff and viewer random stuff when not mobile (e.g. by checking if display none or viewfinder size, but rather by some css style)
- CHECK: only do do when opening the submenu opens
- CHECK: clear interval and end and empty when closing
- CHECK: therefore this things need to be available in menu.js script by proper object packaging
- CHECK: get random order out of brands object (maybe by creating new array with map method out brands object to create new array)

*/

const data = require('./data.js');
const generalMethods = require('./general.js');
const brands = data.brands;
generalMethods.shuffleArray(brands);

//Class (OOP) approach
//OPEN TODOs: only trigger when width > 768px
class BrandFloat {
    constructor(intervalTime, numberAnimations) {
        this._intervalTime = intervalTime;
        this._numberAnimations = numberAnimations;
    }
    get assetPath() {
        const assetPath = '../src/assets/img/';
        return assetPath;
    }
    get floatBackground() {
        return document.querySelector('.submenu__float-background');
    }
    addFloatingImage() {
        //radonmize factor
        let rand = Math.random().toFixed(2);
        let pos;
        let dir;
        let animationVar = 'submenu__float-image-wrapper-0' + this._counter % this._numberAnimations;

        if (rand <= 0.5) {
            pos = 100 * rand;
            dir = 'left';
        } else {
            pos = 50 - (100 - 100 * rand);
            dir = 'right';
        }
        //markup
        let imageElement = document.createElement("figure");
        imageElement.classList = 'submenu__float-image-wrapper ' + animationVar; //TODO: this class actually needed if I set position randomly? css could provide different animations by nth...
        let image = document.createElement("img");
        image.src = this._assetPath + brands[this._counter].src;
        image.classList = 'submenu__float-image';
        imageElement.appendChild(image);
        imageElement.style.cssText = dir + ':' + pos + '%';
        this._floatBackground.appendChild(imageElement);

        // console.log('this._counter before incrementation: ', this._counter, ' - rand: ', rand, ' - animationVar: ', animationVar, ' - brands.name: ', brands[this._counter].name, 'intervalTime: ');        //clearing inside here at the end to spare one unnessary function call
        if (this._counter < this._brandsLength - 1) {
            this._counter++;
        } else {
            // console.log('clear Interval: ', this._interval);
            clearInterval(this._interval);
        }
    }
    initFloating() {
        if(generalMethods.isMobile()) {
            return false;
        }
        if (!this._assetPath) {
            this._assetPath = this.assetPath;  
        }
        if (!this._floatBackground) {
            this._floatBackground = this.floatBackground;
        }
        //reset every time, counter necessary, brandslength just in case as backup
        this._counter = 0;
        this._brandsLength = brands.length;

        //to run once before interval
        this.addFloatingImage();
        //the actual interval
        this._interval = setInterval(this.addFloatingImage.bind(this), this._intervalTime);
    }
    clearFloating() {
        // console.log('clearFloat: stop interval and remove children');
        clearInterval(this._interval);

        while (this._floatBackground.hasChildNodes()) {
            this._floatBackground.removeChild(this._floatBackground.lastChild);
        }
    }
}
module.exports = BrandFloat;